/*
 * 업무구분: 계약
 * 화 면 명: MSPPM872D
 * 화면설명: 유지체크명세-해당(탭)
 * 접근권한: 
 * 작 성 일: 2022.12.22
 * 작 성 자: 김연숙
 */
<template>
  <!-- 스크롤 List -->
  <ur-box-container alignV="start" componentid="ur_box_container_098" direction="column">
    <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-list-area msp-list-area">      
      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-list">
        <mo-list :list-data="pMUkepChkSpcf01VO">
          <template #list-item="{ index, item }">
            <mo-list-item :class="{'on' : item.checked}" @click.native.stop="fn_OnClickListItem(index)"> <!-- on item이 체크되어 선택되었음을 표시  --->
              <mo-checkbox v-model="item.checked" :ref="`chk${index}`"/> 
              <div class="list-item__contents">
                <div class="list-item__contents__title fexJsCtTy1"> 
                  <span class="name" @click.stop="fn_ContNoDecryptCust(item)">{{item.custNm}}</span>
                  <!-- 상태: 배지 형태로 변경 -->
                  <div class="bage-area mr5">                                                
                    <mo-badge class="badge-sample-badge lightred sm" :text="item.condition" shape="status">{{item.condition}}</mo-badge> 
                  </div>
                </div>
                <!-- 상품명, 계약번호 -->
                <div class="list-item__contents__title fwn linkTy">
                  <span class="tit fs16rem txtSkip" @click.stop="fn_ContNoDecrypt(item)">{{item.prdtNm}}</span>
                  <span class="prd-num fs16rem pl3">{{'(' + item.contNo1 + ')'}}</span>
                </div>
                <!-- 보험료(모집고월초P) -->
                <div class="list-item__contents__info">
                  <span class="fs14rem crTy-bk7 mr10">보험료</span><span class="amount crTy-blue3"><b>{{item.clctBomthAmtCommas}}원</b></span>
                </div>
                <!-- 종납년월/횟수, 이체의뢰건수/미이체의뢰건수 -->
                <div class="list-item__contents__info">
                  <div class="info_box">
                    <span class="fs14rem crTy-bk7 mr10">종납/횟수</span><span>{{item.ltpadYm1}} / {{item.rlpadTms}}회</span>
                  </div>
                  <div class="info_box">
                    <span class="fs14rem crTy-bk7 mr10">이체/미이체</span><span><b>{{item.atrAskCnt}}/{{item.trsfImpsCnt}}회분<template v-if="!$bizUtil.isEmpty(item.trsfHopDdCd) && !$bizUtil.isEmpty(item.trsfBnkCdNm)">({{item.trsfHopDdCd + '일'}}, {{item.trsfBnkCdNm}})</template></b></span>
                    <!-- 문자전송내역 -->
                    <span class="dsD pt5 align-item-center" v-if="item.dspched">
                      <span class="fs14rem crTy-bk7 mr10">{{item.dspchTyp}}</span>
                      <span class="display-inline-flex align-item-center">{{item.dspchDate}}</span>
                    </span>
                  </div>
                </div>
                <!-- 단체명 -->
                <div class="list-item__contents__info" v-if="!$bizUtil.isEmpty(item.gnm)">
                  <span class="fs14rem crTy-bk7 mr10">단체명</span><span class="amount crTy-blue3"><b>{{item.gnm}}</b></span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
    </ur-box-container>

    <!-- NoData 영역  -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>

    <!-- 예상유지율 반영/마지막 예상유지율 선택 -->
    <mo-bottom-sheet ref="bottomSheetUkrat" :class="positionUp01 ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'" noHeader>
      <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative ns-btn-ty1">
            <div class="relative-div">
              <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" @click="fn_UpdUkepChkSpcfClick" class="ns-btn-round white btn-ty1">예상유지율반영</mo-button>
              <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" @click="fn_DetailResetBtnClick" class="ns-btn-round blue">마지막 예상유지율</mo-button>
            </div>
          </ur-box-container>
        </template>
    </mo-bottom-sheet>

    <!-- 예상해당유지율 MSPPM877P, MSPPM877D  -->
    <mo-bottom-sheet v-if="exptTableData.length > 0" ref="bottomSheetExpt"  class="ns-bottom-sheet msp-bottomSt-ty2 z-index70 closebtn">
      <template v-slot:title>예상해당유지율</template>
      <div class="ns-btn-close" @click="fn_InitBottomSheet('877')"></div>
      <div class="content-area">   
        <mo-tab-box default-idx="1" class="ns-move-tab-box msp-tab-button">
          <mo-tab-label idx="1">예상해당유지율</mo-tab-label>
          <mo-tab-label idx="2">회차별유지율</mo-tab-label>
          <mo-tab-content idx="1" >
            <!-- 그레프 들어갈 자리 입니다.
                ㄱ. 색상코드값- 파랑색코드값: color:#519ef6;, 그린색코드값: color:#00a4a6; 
                ㄴ. 그래프 bar 높이값 : 12px 입니다.
            -->
            <div class="graph">
              <div id="barChart" style="position: relative; width: 300; height: 250px; background: #FFFFFF;"></div>
            </div>
            <!-- 그래프 들어갈자리 //-->
            <!-- 예상해당유지율 그리드 table 영역 -->
            <div class="table-area pb40">
              <table class="table col-type">
                <colgroup>
                  <col width="40%" />
                  <col width="30%" />
                  <col width="30%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>회차</th>
                    <th>유지율</th>
                    <th>예상유지율</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, idx) in exptTableData" :key="idx">
                    <td>{{item.round}}</td>
                    <td>{{item.ukep}}</td>
                    <td>{{item.exptUkep}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div class="grid-area grid-ty1">
              <el-table :data="exptTableData" >
                <el-table-column prop="round" label="회차">
                </el-table-column>
                <el-table-column prop="ukep" label="유지율">
                </el-table-column>
                <el-table-column prop="exptUkep" label="예상유지율">
                </el-table-column>
              </el-table>
            </div> -->
            <!-- 예상해당유지율 그리드 table 영역 //-->
          </mo-tab-content>
          <mo-tab-content idx="2">
          <!-- 회차별유지율 그리드 table 영역 -->
            <div class="table-area mt20">
              <table class="table col-type">
                <colgroup>
                  <col width="27%" />
                  <col width="24%" />
                  <col width="24%" />
                  <col width="25%" />
                </colgroup>
                <thead>
                  <tr>
                    <th rowspan="2">회차<br>(모집월)</th>
                    <th>모집환산</th>
                    <th>유지환산</th>
                    <th rowspan="2">예상<br>유지환산</th>
                  </tr>
                  <tr>
                    <th>유지율</th>
                    <th class="bd-r-Ty1">예상유지율</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, idx) in scukepChkSpcfMobl02VO">
                      <tr :key="idx + 'a'">
                        <td rowspan="2">{{item.curTmsclctYm}}</td>
                        <td>{{item.cltqtClctCnvlBomthAmtCommas}}</td>
                        <td>{{item.ukeqtClctCnvlBomthAmtCommas}}</td>
                        <td rowspan="2">{{item.exptCnvlAmtCommas}}</td>
                      </tr>
                      <tr :key="idx + 'b'">
                        <td>{{item.ukepRat}}</td>
                        <td class="bd-r-Ty1">{{item.exptUkepRat}}</td>
                      </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!-- <div class="grid-area grid-ty2 mt20">
              <el-table :data="scukepChkSpcfMobl02VO">
                <el-table-column prop="curTmsclctYm" label="회차(모집월)" >
                </el-table-column>
                <el-table-column prop="cltqtClctCnvlBomthAmtCommas" label="모집환산" >
                </el-table-column>
                <el-table-column prop="ukepRat" label="유지율" >
                </el-table-column>
                <el-table-column prop="ukeqtClctCnvlBomthAmtCommas" label="유지환산" >
                </el-table-column>
                <el-table-column prop="exptUkepRat" label="예상유지율" >
                </el-table-column>
                <el-table-column prop="exptCnvlAmtCommas" label="예상유지환산" >
                </el-table-column>
              </el-table>
            </div> -->
            <!-- 회차별유지율 그리드 table 영역 //-->
          </mo-tab-content>
        </mo-tab-box>                   
      </div>
      <template v-slot:action>
        <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_003" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet('877')">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 예상유지율초기화 MSPPM873P -->
    <mo-bottom-sheet ref="bottomSheetInit"  class="ns-bottom-sheet z-index70 closebtn">
      <template v-slot:title>예상유지율 초기화</template>
      <div class="ns-btn-close" @click="fn_InitBottomSheet('cancel')"></div>  <!-- close 버튼 Sample -->
      <div class="content-area">
        <span class="dsD mb10"><b>{{ lv_userNm }}</b>님의 예상유지율은</span>
        <div class="table-area">
          <table class="table row-type">
            <tbody>
              <tr v-for="(item, idx) in initTableData" :key="idx">
                <th>{{ item.round }}</th>
                <td>
                  <span class="num-before">{{ item.ukep }}<span class="fs12">%</span></span> 
                  <strong class="num-after"><b class="crTy-blue4">{{ item.exptUkep }}</b><span class="fs12">%</span></strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-slot:action>
        <ur-box-container align-v="start" componentid="ur_box_container_011" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_004" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_InitBottomSheet('873')">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
    <div :class="positionUp01 && pMUkepChkSpcf01VO.length > 0 ? 'ns-height40' : ''"></div>

    <!-- 계약 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isReading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>
  </ur-box-container>
</template>
<script>
  
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import MSPPM875P from '@/ui/pm/MSPPM875P' // 유지체크명세상세

require('@/assets/lib/rMateChartH5/Assets/Css/rMateChartH5.css')
require('@/assets/lib/rMateChartH5/JS/rMateChartH5.js')
require('@/assets/lib/rMateChartH5/LicenseKey/rMateChartH5License.js')
require('@/assets/lib/rMateChartH5/JS/rMateChartVar.js')

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPPM872D",
  screenId: "MSPPM872D",
  props: ['pMUkepChkSpcf01VO', 'isEmptyView', 'scukepChkSpcfMobl01VO', 'scukepChkSpcfMobl02VO', 'exptTableData', 'initTableData', 'orgData'], 

  components: {
    MSPPM875P,
  },

  computed: {
    selectItems() {
      return this.pMUkepChkSpcf01VO.filter(item => item.checked)
    },
    checkCount () {
      return this.pMUkepChkSpcf01VO.filter(item => item.checked).length
    }
  },

  watch: {
    checkCount() {
      // 사용자 바텀시트 호출
      this.selectItems.busnCd = 'PM'
      this.$cmUtil.setSelectedCustList(this, this.selectItems, 'MSPPM870M')
      if (this.checkCount > 0) this.fn_OpenBottomSheet('default')
      else this.fn_InitBottomSheet()

      // 선택된 항목 길이 전달
      this.$emit('checkCount', {checkCount: this.checkCount})
    },
    exptTableData() {
      this.$nextTick(() => {
        // 값 변동 시 차트 생성
        this.fn_makeChartCreate()
      })
    },
    positionUp01 () {
      this.$emit('positionUp', {positionUp: this.positionUp01})
    }
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      userInfo: {},
      selectedItem: {},

      // 팝업 연동
      popup875: {}, // MSPPM875P 유지체크명세상세

      // 체크 변수
      positionUp01: false, // 예상유지율/마지막예상유지율 바텀시트

      // 통신 변수
      lv_cnsltNo: '',
      lv_userNm: '',
      lv_smsMth: '',

      //복호화 계약번호
      decContNo: '',

      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.',
    };
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created () {
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * Function명 : fn_makeChartCreate
     * 설명       : 가로형 막대 차트 생성(rMate)
     *********************************************************/
    fn_makeChartCreate () {
      let lv_Vm = this
      chartData = []
      layoutStr = ''

      // 표시데이터: 2-13통산, 14-25평균, 26-37평균, 38-49평균
      let selectedData = [lv_Vm.exptTableData[4], lv_Vm.exptTableData[3], lv_Vm.exptTableData[2], lv_Vm.exptTableData[0]]

      // 색상코드값- 파랑색코드값: color:#519ef6;, 그린색코드값: color:#00a4a6; 
	    // 그래프 bar 높이값 : 12px 입니다.
      chartData = selectedData
      layoutStr = `<rMateChart backgroundColor="#FFFFFF"  borderStyle="none">
                    <Options>
                      <Legend position="bottom" labelPlacement="right"/>
                    </Options>
                    <Bar2DChart showDataTips="false" selectionMode="single" barWidthRatio="0.66">
                      <horizontalAxis>
                        <LinearAxis maximum="100" interval="10"/>
                      </horizontalAxis>
                      <verticalAxis>
                        <CategoryAxis categoryField="round"/>
                      </verticalAxis>
                      <series>
                        <Bar2DSeries itemRenderer="SemiCircleBarItemRenderer" labelPosition="inside" halfWidthOffset="0.5" xField="ukep" displayName="유지율" color="#ffffff" insideLabelYOffset="-2">
                          <showDataEffect>
                            <SeriesInterpolate/>
                          </showDataEffect>
                           <fills>
                            <SolidColor color="#519ef6" />
                          </fills>
                        </Bar2DSeries>
                        <Bar2DSeries itemRenderer="SemiCircleBarItemRenderer" labelPosition="inside" halfWidthOffset="0.5" xField="exptUkep" displayName="예상유지율" color="#ffffff" insideLabelYOffset="-2">
                          <showDataEffect>
                            <SeriesInterpolate/>
                          </showDataEffect>
                          <fills>
                            <SolidColor color="#00a4a6" />
                          </fills>
                        </Bar2DSeries>
                      </series>
                    </Bar2DChart>
                  </rMateChart>`
      rMateChartH5.create('MSPPM872D', 'barChart', 'rMateOnLoadCallFunction=chartReadyHandler', '100%', '100%')
      rMateChartH5.registerTheme(rMateChartH5.themes)
    },

    /******************************************************************************
     * Function명 : fn_OpenBottomSheet
     * 설명       : 바텀시트 열기
     ******************************************************************************/
    fn_OpenBottomSheet(str) {
      let lv_Vm = this

      switch(str) {
        case 'default':
          // 예상유지율 바텀시트 열기
          lv_Vm.positionUp01 = true
          lv_Vm.$refs.bottomSheetUkrat.open()
          break;
        case '877':
          // iOS에서 하단탭이 바텀시트 위로 표시되는 문제 대응
          this.$GnbManager.fn_SetGnbShow(false)
          this.$BottomManager.fn_SetBottomVisible(false)
          lv_Vm.$nextTick(() => {
            // 예상해당유지율 바텀시트 열기
            lv_Vm.$refs.bottomSheetExpt.open()
          })
          break;
        case '873':
          // iOS에서 하단탭이 바텀시트 위로 표시되는 문제 대응
          this.$GnbManager.fn_SetGnbShow(false)
          this.$BottomManager.fn_SetBottomVisible(false)
          if(this.orgData.cnslt === undefined || this.orgData.cnslt === null || this.orgData.cnslt === ''){
            this.lv_userNm = this.userInfo.userNm
          }else{
            this.lv_userNm = this.orgData.cnslt.label.substr(0,this.orgData.cnslt.label.indexOf('('))
          }
          // 예상유지율 초기화 바텀시트 열기
          lv_Vm.$refs.bottomSheetInit.open()
          break;
      }
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet(str) {
      let lv_Vm = this
      this.$BottomManager.fn_SetBottomVisible(true)
      
      // 예상해당유지율
      if (str === '877') {
        if (lv_Vm.$refs !== undefined) {
          if (lv_Vm.$refs.bottomSheetExpt !== undefined) {
            lv_Vm.$refs.bottomSheetExpt.close()
          }
        } 
        lv_Vm.fn_OpenBottomSheet('default')       
      } else if (str === 'cancel') {
        if (lv_Vm.$refs.bottomSheetInit !== undefined) {
          lv_Vm.$refs.bottomSheetInit.close()
          // iOS에서 하단탭이 바텀시트 위로 표시되는 문제 대응
          this.$GnbManager.fn_SetGnbShow(true)
        }
      } else {
        // 마지막 예상유지율일 때 리스트 재조회
        if (str === '873') {
          this.$emit('fn_selInqrStrYmd')
        } 
        // 전체 바텀시트 초기화
        if (lv_Vm.$refs !== undefined) {
          if (lv_Vm.$refs.bottomSheetUkrat !== undefined) {
            lv_Vm.positionUp01 = false
            lv_Vm.$refs.bottomSheetUkrat.close()
          }
          if (lv_Vm.$refs.bottomSheetExpt !== undefined) {
            lv_Vm.$refs.bottomSheetExpt.close()
          }
          if (lv_Vm.$refs.bottomSheetInit !== undefined) {
            lv_Vm.$refs.bottomSheetInit.close()
          }
        }
      }

    },

    /******************************************************************************
    * Function명 : fn_UpdUkepChkSpcfClick()
    * 설명       : 예상유지율 반영 버튼
    ******************************************************************************/
    fn_UpdUkepChkSpcfClick () {
      let lv_Vm = this
      
      this.$emit('fn_ContNoDecrypt', true)
    },

    /*********************************************************
     * Function명 : fn_DetailResetBtnClick
     * 설명       : 예상유지율 초기화
     *********************************************************/
    fn_DetailResetBtnClick () {
      let lv_Vm = this

      // 서비스 조회 파라미터 초기화
      this.$emit('fn_DetailResetBtnClick')     
    },

    /******************************************************************************
    * Function명 : fn_InsSmsDspchInpt
    * 설명       : 문자전송내역 저장
    ******************************************************************************/
    fn_InsSmsDspchInpt () {
      let lv_Vm = this
      let pParams = {
        trtRslt: 0,
        pMSmsDspchSVO: []
      }
      const trnstId = 'txTSSPM07I1'
      const auth = 'S'
      let confirm = this.getStore('confirm')

      for (let i in lv_Vm.selectItems) {
        let tmpObj = {}
        tmpObj.userEno = lv_Vm.lv_cnsltNo  // 사용자사번
        tmpObj.msgDspchTypCd = lv_Vm.lv_smsMth  // 메시지유형 S:SMS발송 / T:터치온발송
        tmpObj.mobslSrnId = 'MSPPM870M' // 업무화면ID MSPPM870M:유지체크명세 메인
        tmpObj.mobslChnlCustId = lv_Vm.selectItems[i].contNo // 모바일채널고객ID(현재 계약번호로 대체)
        tmpObj.mobslChnlCustNm = lv_Vm.selectItems[i].custNm  // 고객명
        pParams.pMSmsDspchSVO.push(tmpObj)
      }

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( response && response.body) {
            let trtRslt = response.body.trtRslt
            if (!trtRslt) console.log('trtRslt === 0')
          } else {
            confirm.dispatch('SETCOLOR', 'blue')
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명 : fn_GetClientinfo
     * 설명       : 팝업 호출을 위해 채널고객ID 취득 선행
     ******************************************************************************/
    fn_GetClientinfo (item, contNo) {
      this.selectedItem = item
      this.decContNo = contNo
      this.$pmUtil.fn_getClientinfoSMS(contNo, this.lv_cnsltNo, this.fn_OpenMSPPM875P, this.$options.screenId, this)
    },
    /******************************************************************************
     * Function명 : fn_ContNoDecrypt
     * 설명       : 계약번호 복호화
     * 작성자     : 엄기문
     ******************************************************************************/
    async fn_ContNoDecrypt(item) {
      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('FULL')
      
      let data = [item.contNoEnc]
      let enData = await this.$commonUtil.getDecryptCall(this,data)
      this.fn_GetClientinfo(item, enData[0])
    },

    /******************************************************************************
     * Function명 : fn_OpenMSPPM875P
     * 설명       : 유지체크명세상세 팝업 호출
     ******************************************************************************/
    fn_OpenMSPPM875P (response) {
      if (!response || !response.body) {
        return
      }

      this.popup875 = this.$bottomModal.open(MSPPM875P, {
        properties: {
          pPMUkepChkSpcf01VO : this.selectedItem,
          pChnlCustId: response.body.chnlCustId,
        },
        listeners: {
          onPopupClose: () => {
            this.$bottomModal.close(this.popup875)
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_OpenCustInfoBs
     * 설명       : 고객상세 팝업 연동
     ******************************************************************************/
    fn_OpenCustInfoBs(item, contno){
      let lv_Vm      = this
      let cnsltNo    = ''
      let nmUiObject = {}

      if(this.orgData.cnslt === undefined || this.orgData.cnslt === null || this.orgData.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.orgData.cnslt.key
      }

      nmUiObject = {
        chnlCustId: item.chnlCustId,
        custNm: item.custNm,
        cnsltNo: cnsltNo,
        contNo : contno,
        parentId: lv_Vm.$options.screenId
      }

      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, function (response){
        console.log(response);
        //Close 콜백 처리
        return
      })
    },

    /******************************************************************************
     * Function명 : fn_ContNoDecryptCust
     * 설명       : 계약번호 복호화
     * 작성자     : 엄기문
     ******************************************************************************/
    async fn_ContNoDecryptCust(item) {
      let data = [item.contNoEnc]
      let enData = await this.$commonUtil.getDecryptCall(this,data)
      this.fn_OpenCustInfoBs(item, enData[0])
    },

    /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 로딩 메시지 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      ******************************************************************************/
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },


    /**
     * @description 체크박스 이외의 영역 클릭했을 때, 체크 활성화 및 비활성화 한다.
     * @param       {Number} index 리스트 데이터의 index
     */
    fn_OnClickListItem(index) {
      this.$refs[`chk${index}`].onClick()
    },

  }
};


</script>